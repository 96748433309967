import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PromoImg1 from "../../assets/images/banner/Banner.jpeg";
import PromoImg2 from "../../assets/images/promotion/promotion_img2.png";
import PromoImg3 from "../../assets/images/promotion/promotion_img3.png";
import PromoImg4 from "../../assets/images/promotion/promotion_img4.png";
import PromoImg5 from "../../assets/images/promotion/promotion_img5.png";
import PromoImg6 from "../../assets/images/promotion/promotion_img6.png";
import BannerImg1 from "../../assets/images/banner/Banner_1.jpg";
import BannerImg2 from "../../assets/images/banner/Banner_2.jpg";
import BannerImg3 from "../../assets/images/banner/Banner_3.jpg";
import Promotion from "./Promotion";
import { withTranslation } from "react-i18next";

const PromotionPage = () => {
  const [activeState, setActiveState] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const PromotionData = [
    { id: 3, bannerImg: PromoImg1, startDate: "12-11-2024", endDate: "17-05-2024" },
    // { id: 4, bannerImg: PromoImg2, startDate: "12-11-2024", endDate: "14-01-2024" },
    // { id: 5, bannerImg: PromoImg3, startDate: "09-11-2024", endDate: "12-07-2024" },
    // { id: 6, bannerImg: PromoImg4, startDate: "14-11-2024", endDate: "29-11-2024" },
    // { id: 7, bannerImg: PromoImg5, startDate: "12-11-2024", endDate: "12-11-2024" },
    // { id: 8, bannerImg: PromoImg6, startDate: "11-11-2024", endDate: "17-11-2024" },
    // { id: 0, bannerImg: BannerImg1, startDate: "12-11-2024", endDate: "11-11-2024" },
    // { id: 1, bannerImg: BannerImg2, startDate: "01-11-2024", endDate: "13-11-2024" },
    // { id: 2, bannerImg: BannerImg3, startDate: "12-11-2024", endDate: "15-11-2024" },
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const promoId = params.get("id");
    // console.log("promoId", promoId);

    if (promoId) {
      setTimeout(() => {
        document.getElementById(`promo-${promoId}`)?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setActiveState(Number(promoId));
      }, 300);
    }
  }, [location.search]);


  const handleBackClick = () => navigate("/");

  const handleClick = (id) => {
    setActiveState(activeState === id ? null : id);
  };

  return (
    <div className="inner_content promotion-wrapper">
      <div className="promotion-page">
        <div className="promotion-page-head">
          <div className="promotion-head-back-btn" onClick={handleBackClick}>
            <p>Back to Home</p>
          </div>
          <h2>Promotion Banners</h2>
        </div>
        {PromotionData.map((promo) => (
          <Promotion
            key={promo.id}
            id={promo.id}
            promoImg={promo.bannerImg}
            startDate={promo.startDate}
            endDate={promo.endDate}
            onclick={handleClick}
            activeState={activeState}
          />
        ))}
      </div>
    </div>
  );
};

export default withTranslation()(PromotionPage);
