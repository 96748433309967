import { useEffect, useState } from "react";

const Promotion = (props) => {
    const { id, promoImg, startDate, endDate, onclick, activeState } = props;
    return (
        <div className={`promotion ${activeState === id ? "active" : ""}`} id={`promo-${id}`} >
            <div className="promotion-banner-holder" >
                <img src={promoImg} alt="" />
                <button className="promotion-button" onClick={() => onclick(id)}>More Info</button>
            </div>
            <div className="promo-content-outer" style={{ maxHeight: activeState === id ? 1000 : 0 }}>
                <div className="promo-content">
                    {/* <div className="promotion_content_header">
                        <h4>Start date : {startDate}</h4>
                        <h4>END date : {endDate}</h4>
                    </div> */}
                    <div className="promotion-cnt">
                        {/* <p>*Terms & Conditions :</p>
                        <ol>
                            <li>Losing Bonus (Cashback) 3% Only applies to Slot Game types</li>
                            <li>3% Slot Cashback Bonus is calculated from losses every week (Tuesday to Monday)</li>
                            <li>The condition for distributing Cashback is that the game turnover must reach 3x your total losses for 1 week.</li>
                            <li>Here is how to calculate Slot Cashback: <br />
                                Example: Member experiences a loss of IDR 5,000,000 x 3% = IDR 150,000 (3% loss bonus obtained).

                            </li>
                            <li>Loss Bonus Distribution (Cashback) is only given every Tuesday & will be credited automatically</li>
                            <li>DEWATOGEL has the right not to provide bonuses if it is found to have committed fraud or violated existing provisions</li>
                            <li>DEWATOGEL has the right to change, reject, cancel or close all promotions in accordance with applicable provisions without prior notice.</li>
                            <li>DEWATOGEL's decision for this promo is final and cannot be disputed.</li>

                        </ol> */}
                        <h2 className="promotion-heading">BONUS NEW YEAR TEXAS POKER</h2>
                        <h3 className="promotion-subheading">Kabar Gembira !!!</h3>
                        <ol>
                            <li>Akan hadir <strong>BONUS NEW YEAR</strong> pada Permainan Texas Poker.</li>
                            <li>Promo Bonus New Year dimulai dari tanggal 01 Desember 2024 s.d 05 Januari 2025.</li>
                            <li>Semua member yang bermain akan memiliki kesempatan yang sama untuk mendapatkan bonus.</li>
                            <li>Tidak ada batasan Minimal / Maksimal turnover atau kekalahan dalam permainan.</li>
                        </ol>
                    </div>
                </div>
            </div>

        </div>
    )
}


export default Promotion;